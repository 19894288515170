import { createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "wrap" }
const _hoisted_2 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_nav_bar, { alignment: "right" }, {
        brand: _withCtx(() => [
          _createTextVNode(" Neal Cynkus ")
        ]),
        content: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.navItems, (item, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (!item.icon)
                ? (_openBlock(), _createBlock($setup["NavItemText"], {
                    key: `i${index}`,
                    link: item
                  }, null, 8 /* PROPS */, ["link"]))
                : (_openBlock(), _createBlock($setup["NavItemText"], {
                    key: `e${index}`,
                    link: item
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: item.icon,
                        size: "2x",
                        alt: item.alt,
                        title: item.alt
                      }, null, 8 /* PROPS */, ["icon", "alt", "title"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["link"]))
            ], 64 /* STABLE_FRAGMENT */))
          }), 64 /* STABLE_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_router_view),
      _createVNode($setup["FooterComponent"])
    ])
  ]))
}
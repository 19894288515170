import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "targetEl",
    style: _normalizeStyle(`min-height:${$setup.fixedMinHeight ? $setup.fixedMinHeight : $props.minHeight}px;`)
  }, [
    ($setup.shouldRender)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "drawer",
  class: "container-fluid pb-5 pb-md-0",
  key: "nav-drawer"
}
const _hoisted_2 = { class: "row pb-3" }
const _hoisted_3 = { class: "col-8 offset-1 mb-3 pb-3 pt-3 pl-1 mobile-indicator text-start" }
const _hoisted_4 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "leftSlide",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      _createElementVNode("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", null, _toDisplayString($props.title), 1 /* TEXT */)
          ]),
          _createElementVNode("div", {
            class: "col-2 mb-3 pb-3 pt-3 pr-0 mobile-indicator text-center",
            onClick: $setup.doClick
          }, [
            _createElementVNode("h3", null, [
              _createVNode(_component_font_awesome_icon, {
                icon: $setup.faCircleXmark,
                alt: "Circle with x icon for closing navigation drawer"
              }, null, 8 /* PROPS */, ["icon"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper mt-2 mt-md-5 mb-2 mb-md-5" }
const _hoisted_3 = {
  class: "modal-container col-12 offset-md-3 col-md-8 col-lg-7 col-xl-5",
  ref: "modal"
}
const _hoisted_4 = { id: "modal-header" }
const _hoisted_5 = {
  id: "modal-body",
  class: "container-fluid"
}
const _hoisted_6 = {
  key: 0,
  id: "modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "modalanim" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "header"),
              (_ctx.closeInHeader)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "exit",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args))),
                    tabindex: "0"
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: _ctx.faCircleXmark,
                      alt: "Circle with x icon for closing navigation drawer"
                    }, null, 8 /* PROPS */, ["icon"])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "body")
            ]),
            (_ctx.hasFooterContent)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "footer"),
                  (_ctx.closeInFooter)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
                        tabindex: "0"
                      }, " Close "))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true)
          ], 512 /* NEED_PATCH */)
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f2d70fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "carousel-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        ref: "inner",
        style: _normalizeStyle($setup.innerStyles),
        class: "inner"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.model, (card, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "card"
          }, [
            _createElementVNode("img", {
              src: card.img,
              alt: card.alt,
              class: "img-fluid"
            }, null, 8 /* PROPS */, _hoisted_2)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ], 4 /* STYLE */)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_font_awesome_icon, {
        onClick: $setup.prev,
        icon: $setup.faCircleArrowLeft,
        size: "3x",
        style: {"color":"#193154"},
        class: "me-2 carousel-btn",
        alt: "Arrow pointing left icon for previous slide"
      }, null, 8 /* PROPS */, ["icon"]),
      _createVNode(_component_font_awesome_icon, {
        onClick: $setup.next,
        icon: $setup.faCircleArrowRight,
        size: "3x",
        style: {"color":"#193154"},
        class: "ms-2 carousel-btn",
        alt: "Arrow pointing right icon for next slide"
      }, null, 8 /* PROPS */, ["icon"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}
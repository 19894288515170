import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "nav-item",
  id: "nav-item"
}
const _hoisted_2 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isInternalLink)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.link.href,
          tabindex: "0"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.link.label), 1 /* TEXT */)
            ])
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["to"]))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.link.href,
          target: _ctx.link.target ?? '_blank',
          rel: "noreferrer",
          tabindex: "0"
        }, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.link.label), 1 /* TEXT */)
          ])
        ], 8 /* PROPS */, _hoisted_2))
  ]))
}
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "skill-container" }
const _hoisted_2 = { class: "skill-badge" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString($props.badgeText), 1 /* TEXT */)
      ])
    ]),
    _createTextVNode(" " + _toDisplayString($props.skillText), 1 /* TEXT */)
  ]))
}